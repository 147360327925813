<script setup lang="ts">
import { getAppBySearchApi } from '~/api'

const route = useRoute()
const { data, status } = await useLazyAsyncData('search', async () => {
  if (route.query.q) {
    return getAppBySearchApi(route.query.q as string)
  }
  else {
    return []
  }
}, {
  watch: [() => route.query],
})

const games = computed(() => data.value || [])
const total = computed(() => data.value?.length || 0)

const { toAppDetail, toSearch } = useRouterToPage()

const inputRef = ref<HTMLInputElement>()
const searchInput = ref(route.query.q as string)
function cleanSearch() {
  searchInput.value = ''
  inputRef.value?.focus()
}

const inputChange = useDebounceFn(searchByInput, 500)
function searchByInput() {
  if (searchInput.value) {
    toSearch(searchInput.value)
  }
}

const { autoSideBar } = useLayout()
autoSideBar()

const { searchHead } = usePageHead()
searchHead()
</script>

<template>
  <div v-track="{ id: 'web-search', view: { value: { page: 'search', seo: $route.query.q } } }" class="view-content" pb="80px md:160px">
    <h1 class="text-center c-#161636 font-900" lt-md="mt-40px text-20px" md="mt-60px text-32px">
      {{ $t('search.title') }}
    </h1>
    <div class="mx-auto max-w-600px" lt-md="mt-20px mx-20px mb-16px" md="mt-24px">
      <div
        class="relative flex items-center of-hidden rd-8px bg-#fff"
        lt-md="h-44px px-16px" md="h-50px px-20px"
      >
        <Icon name="Search" class="text-20px c-#BABAC3" />

        <input
          ref="inputRef"
          v-model="searchInput"
          class="flex-1 b-none bg-transparent px-12px text-18px c-current font-700 caret-purple-5 outline-0"
          type="text"
          @input="inputChange"
          @keydown.enter.prevent="searchByInput"
        >

        <Icon
          name="Close"
          class="cursor-pointer text-20px transition-all-100" c="#BABAC3 hover:#161636"
          :class="searchInput ? 'op-100' : 'op-0 pointer-events-none'"
          @click="cleanSearch"
        />
      </div>
    </div>

    <div class="text-center" lt-md="mt-16px text-12px" md="mt-40px text-16px">
      <span class="c-#181845 c-op-70">{{ $t('search.num') }}</span>
      <span class="c-purple-5">{{ total }}</span>
    </div>

    <div
      class="grid"
      lt-md="grid-cols-4 gap-[20px_12px] mt-40px"
      md="grid-cols-6 gap-[24px_40px] mt-24px"
      lg="grid-cols-8"
    >
      <template v-if="status === 'pending'">
        <GameLiteBlockSkeleton v-for="i in 32" :key="i" />
      </template>
      <template v-else>
        <template v-if="!games.length">
          <div class="grid-col-[span_8] flex-center flex-col">
            <Icon class="aspect-ratio-32/17 h-auto max-w-320px w-53.3vw" name="SearchNone" />
            <div class="mx-auto mt-20px max-w-580px">
              <p class="text-center c-#161636 c-op-50" lt-md="text-14px lh-20px" md="text-16px lh-24px">
                {{ $t('error.search_none') }}
              </p>
            </div>
          </div>
        </template>
        <template v-else>
          <GameLiteBlock
            v-for="item in games"
            :key="item.gameId" v-track="{ id: 'web-search-game', click: { value: { type: '搜索', name: item.gameName, seo: item.seo, pkg: item.packageName } } }"
            mode="light" :name="item.gameName" :icon="item.icon"
            :to="toAppDetail({ seo: item.seo, type: item.category }, { hasReturnUrl: true })"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
